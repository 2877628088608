// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-арт-дизайн-js": () => import("./../../../src/pages/арт-дизайн.js" /* webpackChunkName: "component---src-pages-арт-дизайн-js" */),
  "component---src-pages-арт-дизайн-картички-js": () => import("./../../../src/pages/арт-дизайн/картички.js" /* webpackChunkName: "component---src-pages-арт-дизайн-картички-js" */),
  "component---src-pages-графичен-дизайн-js": () => import("./../../../src/pages/графичен-дизайн.js" /* webpackChunkName: "component---src-pages-графичен-дизайн-js" */),
  "component---src-pages-графичен-дизайн-картички-js": () => import("./../../../src/pages/графичен-дизайн/картички.js" /* webpackChunkName: "component---src-pages-графичен-дизайн-картички-js" */),
  "component---src-pages-за-нас-js": () => import("./../../../src/pages/за-нас.js" /* webpackChunkName: "component---src-pages-за-нас-js" */),
  "component---src-pages-за-нас-история-js": () => import("./../../../src/pages/за-нас/история.js" /* webpackChunkName: "component---src-pages-за-нас-история-js" */),
  "component---src-pages-за-нас-конкурси-js": () => import("./../../../src/pages/за-нас/конкурси.js" /* webpackChunkName: "component---src-pages-за-нас-конкурси-js" */),
  "component---src-pages-за-нас-награди-js": () => import("./../../../src/pages/за-нас/награди.js" /* webpackChunkName: "component---src-pages-за-нас-награди-js" */),
  "component---src-pages-за-нас-партньори-js": () => import("./../../../src/pages/за-нас/партньори.js" /* webpackChunkName: "component---src-pages-за-нас-партньори-js" */),
  "component---src-pages-контакти-js": () => import("./../../../src/pages/контакти.js" /* webpackChunkName: "component---src-pages-контакти-js" */),
  "component---src-pages-проекти-js": () => import("./../../../src/pages/проекти.js" /* webpackChunkName: "component---src-pages-проекти-js" */),
  "component---src-templates-blog-list-js": () => import("./../../../src/templates/blog-list.js" /* webpackChunkName: "component---src-templates-blog-list-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */)
}

